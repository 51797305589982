class LifeVineSitters {
    private lv: LifeVine;
    private basePath: string;
    private _criteria: LifeVineCriteria;
    private _locations: LifeVineLocations;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.basePath = '/sitters';
        this._criteria = new LifeVineCriteria(this.lv);
        this._locations = new LifeVineLocations(this.lv);
    }

    public criteria(id) {
        return this._criteria.pass(this.getPath(id));
    }

    public locations(id) {
        return this._locations.pass(this.getPath(id));
    }

    public get(id?: number) {
        return this.lv.ajax('GET', this.getPath(id));
    }

    public create(data) {
        return this.lv.ajax('POST', this.getPath(), data);
    }

    public save(id: number, data) {
        return this.lv.ajax('PUT', this.getPath(id), data);
    }

    public remove(id: number) {
        return this.lv.ajax('DELETE', this.getPath(id));
    }

    public resetPassword(id: number) {
        return this.lv.ajax('POST', this.getPath(id) + '/reset-password', {});
    }

    private getPath(id?: any) {
        return id !== undefined ? `${this.basePath}/${id}` : this.basePath;
    }
}